import { useTranslation } from '@/hooks/translations';
import ListSubheader from '@mui/material/ListSubheader';
import { LaptopMoneyIcon, PhoneIcon } from '@/icons/svgIcons';
import type { IRestaurant } from '@/views/OrdersTableView/types';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { type Dispatch, type SetStateAction } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import type { IBrandOrRestaurant } from './BrandRestaurantSwitcher';

interface IMenuItemComponentProps {
    brandId?: string;
    isBrand: boolean;
    restaurant?: IRestaurant;
    selectedBrandOrRestaurant: IBrandOrRestaurant | null;
    setSelectedBrandOrRestaurant: Dispatch<SetStateAction<IBrandOrRestaurant | null>>;
    brandName: string;
}

export const ListSubheaderComponent = ({ title }: { title: string }) => {
    return (
        <ListSubheader
            sx={({ palette }) => ({
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontWeight: '500',
                color: palette.common.black,
            })}
        >
            {title}
        </ListSubheader>
    );
};

export const MenuItemComponent = ({
    isBrand,
    brandId,
    restaurant,
    setSelectedBrandOrRestaurant,
    brandName,
}: IMenuItemComponentProps) => {
    const { t } = useTranslation('common');

    return (
        <MenuItem
            value={isBrand ? brandId || '' : restaurant?.id || ''}
            onClick={() => {
                setSelectedBrandOrRestaurant({
                    id: isBrand ? brandId || '' : restaurant?.id || '',
                    isBrand,
                    name: isBrand ? brandName : restaurant?.name || '',
                });
            }}
        >
            <Box display="flex" alignItems="center">
                <FormControlLabel
                    value={isBrand ? brandId || '' : restaurant?.id || ''}
                    control={<Radio />}
                    label={undefined}
                />
                <Box
                    sx={({ palette }) => ({
                        backgroundColor: palette.grey[100],
                        padding: 0.5,
                        height: '34px',
                        width: '34px',
                        borderRadius: '70px',
                        border: `1px solid ${palette.grey[200]}`,
                        marginLeft: 1,
                        marginRight: 1,
                    })}
                >
                    {isBrand ? <LaptopMoneyIcon /> : <PhoneIcon />}
                </Box>
                <Box display="flex" flexDirection="column">
                    <Typography
                        sx={({ palette }) => ({
                            fontFamily: 'Montserrat',
                            fontSize: '14px',
                            fontWeight: '500',
                            color: palette.common.black,
                            lineHeight: '22px',
                        })}
                    >
                        {restaurant?.name || brandName}
                    </Typography>
                    <Typography
                        sx={({ palette }) => ({
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: '500',
                            color: palette.grey[500],
                            lineHeight: '20px',
                        })}
                    >
                        {isBrand ? t('Brand') : brandName}
                    </Typography>
                </Box>
            </Box>
        </MenuItem>
    );
};
