import { Dispatch, SetStateAction, useState } from 'react';
import { useRouter } from 'next/router';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import { onPushEvent } from '@/services/lib/gtm';
import { useTranslation } from '@/hooks/translations';
import { useUserContext } from '@/contexts/user';
import { useRestaurantContext } from '@/contexts/restaurant';
import { VendorRole } from '@/constants/roleRestrictions';
import TimeDrawer from '@/components/TimeRange/Drawer';
import { CustomButton, PrimaryButton } from '@/components/common';
import { type ITimeRangeForm, TimeRangeTypes } from '@/components/TimeRange/Form/types';
import { LegacyDashboardTypes } from '@/views/Dashboard';
import CachedRounded from '@mui/icons-material/CachedRounded';
import CardDetailsDrawer from './CardDetailsDrawer';
import BrandRestaurantSwitcher from './BrandRestaurantSwitcher';
import CustomSelect from './CustomSelect';

const Filters = ({
    timeRange,
    setTimeRange,
    setRefreshBoolean,
}: {
    timeRange: ITimeRangeForm;
    setTimeRange: Dispatch<SetStateAction<ITimeRangeForm>>;
    legacyDashboard: LegacyDashboardTypes;
    setRefreshBoolean: Dispatch<SetStateAction<boolean>>;
}) => {
    const { push } = useRouter();
    const { t } = useTranslation('common');
    const { user } = useUserContext();
    const {
        restaurant,
        selectedProductType,
        setSelectedProductType,
        selectedQrGroup,
        setSelectedQrGroup,
        selectedBrandOrRestaurant,
    } = useRestaurantContext();

    const [seeMoreDetails, setSeeMoreDetails] = useState(false);

    const productTypes = [
        { option: t('QR'), value: 'qlub' },
        { option: t('Terminal'), value: 'softpos' },
        { option: t('Order & Pay'), value: 'qsr' },
    ];

    const isQrGroupVisible = !selectedBrandOrRestaurant?.isBrand;

    const todayDate = new Date();
    const minDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 90);

    const setSelectedGroupHandler = (group: string) => {
        if (group === 'All') {
            setSelectedQrGroup(null);
            return;
        }
        setSelectedQrGroup(group);
    };

    const refresh = () => {
        setRefreshBoolean((o) => !o);
    };

    const isBrandUser = [VendorRole.BrandAdmin, VendorRole.BrandAnalyst].includes(user?.userData?.role);

    return (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={2} sx={{ justifyContent: 'flex-end' }}>
                {isBrandUser && (
                    <Grid item xs={12} sm={3}>
                        <BrandRestaurantSwitcher />
                    </Grid>
                )}

                {isQrGroupVisible ? (
                    <>
                        <Grid item xs={6} sm={restaurant?.country_code === 'br' ? 2 : 3}>
                            <CustomSelect
                                size="small"
                                value={selectedQrGroup || 'All'}
                                onChange={(e) => {
                                    setSelectedGroupHandler(e.target.value || '');
                                }}
                            >
                                <MenuItem value={'All'}>{t('All QR Groups')}</MenuItem>
                                {restaurant?.qr_groups?.map((group) => (
                                    <MenuItem value={group.id}>{group.name}</MenuItem>
                                ))}
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                                >
                                    <PrimaryButton
                                        sx={{
                                            marginTop: '10px',
                                            borderRadius: '8px',
                                            height: '40px',
                                            textTransform: 'none',
                                        }}
                                        onClick={() => {
                                            onPushEvent('vendor_qr-groups_create-icon-button-selected');
                                            push('/qr_code');
                                        }}
                                        text={t('Add a Qr Group')}
                                        size="medium"
                                    />
                                </Grid>
                            </CustomSelect>
                        </Grid>
                        <Grid item xs={6} sm={restaurant?.country_code === 'br' ? 2 : 3}>
                            <CustomSelect
                                size="small"
                                value={selectedProductType || 'All'}
                                onChange={(e) => {
                                    const val = e.target.value;
                                    if (val === 'All') setSelectedProductType(null);
                                    else setSelectedProductType(val);
                                }}
                            >
                                <MenuItem value={'All'}>{t('All Products')}</MenuItem>
                                {productTypes.map(({ option, value }) => (
                                    <MenuItem key={value} value={value}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </CustomSelect>
                        </Grid>
                        {restaurant?.country_code === 'br' && (
                            <Grid item xs={12} sm={2}>
                                <CardDetailsDrawer
                                    timeRange={timeRange}
                                    seeMoreDetails={seeMoreDetails}
                                    setSeeMoreDetails={setSeeMoreDetails}
                                />
                            </Grid>
                        )}
                    </>
                ) : (
                    <>
                        <Grid item xs={12} sm={3} />
                        <Grid item xs={12} sm={3}>
                            <CustomButton
                                onClick={() => {
                                    refresh();
                                    onPushEvent('user_click_on_refresh');
                                }}
                                endIcon={<CachedRounded />}
                                size="large"
                                sx={{ color: 'primary.main', width: '100%', textTransform: 'none' }}
                            >
                                {t('Refresh')}
                            </CustomButton>
                        </Grid>
                    </>
                )}

                <Grid item xs={12} sm={3}>
                    <TimeDrawer
                        onConfirm={(val) => {
                            onPushEvent(`user_use_filter_date_${val.type}`);
                            setTimeRange(val);
                        }}
                        options={[
                            TimeRangeTypes.TODAY,
                            TimeRangeTypes.YESTERDAY,
                            TimeRangeTypes.WEEK,
                            TimeRangeTypes.LAST_7_DAYS,
                            TimeRangeTypes.MONTH,
                            TimeRangeTypes.LAST_30_DAYS,
                        ]}
                        minDate={minDate}
                        timeSelectEnabled
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Filters;
