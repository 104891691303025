import { useRestaurantContext } from '@/contexts/restaurant';
import { useTranslation } from '@/hooks/translations';
import { LaptopMoneyIcon, PhoneIcon } from '@/icons/svgIcons';
import RestaurantService from '@/services/restaurant';
import type { IRestaurant } from '@/views/OrdersTableView/types';
import Search from '@mui/icons-material/Search';
import UnfoldMoreRounded from '@mui/icons-material/UnfoldMoreRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState, useMemo, useRef } from 'react';
import { FormControl, RadioGroup } from '@mui/material';
import CustomSelect from './CustomSelect';
import { ListSubheaderComponent, MenuItemComponent } from './components';

export interface IBrandOrRestaurant {
    id: string;
    name: string;
    isBrand: boolean;
}

export default function BrandRestaurantSwitcher() {
    const { t } = useTranslation('common');
    const { restaurant, selectedBrandOrRestaurant, setSelectedBrandOrRestaurant } = useRestaurantContext();
    const [restaurantList, setRestaurantList] = useState<IRestaurant[]>([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [tempSelection, setTempSelection] = useState<IBrandOrRestaurant | null>(null);
    const initialRef = useRef(true);

    useEffect(() => {
        if (initialRef.current && selectedBrandOrRestaurant?.id) {
            initialRef.current = false;
        }
        setTempSelection(selectedBrandOrRestaurant);
    }, [selectedBrandOrRestaurant]);

    useEffect(() => {
        setLoading(true);
        RestaurantService.getInstance()
            .getRestaurantList({ brandId: restaurant?.brand.id, page: 0, limit: 1000 })
            .then((res) => {
                setRestaurantList(res);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSearchTerm('');
    };

    const handleApply = () => {
        setSelectedBrandOrRestaurant(tempSelection);
        handleClose();
    };

    const filteredRestaurants = restaurantList.filter((r) => r.name.toLowerCase().includes(searchTerm.toLowerCase()));

    const displayValue = useMemo(() => {
        if (!restaurant) return '';

        if (selectedBrandOrRestaurant?.id) {
            if (selectedBrandOrRestaurant.isBrand) {
                return restaurant.brand.name;
            }
            const foundRestaurant = restaurantList.find((r) => r.id === selectedBrandOrRestaurant.id);
            return foundRestaurant?.name || restaurant.brand.name;
        }

        return restaurant.brand.name;
    }, [selectedBrandOrRestaurant, restaurant, restaurantList]);

    const radioValue = useMemo(() => {
        if (tempSelection?.id) {
            return tempSelection.id;
        }
        return restaurant?.brand.id;
    }, [tempSelection, restaurant]);

    return loading ? (
        <Skeleton variant="rectangular" height={40} />
    ) : (
        <CustomSelect
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            size="small"
            value={displayValue}
            renderValue={() => (
                <Box display="flex" flexDirection="row" alignItems="center" height="23px">
                    <Box
                        sx={({ palette }) => ({
                            backgroundColor: palette.grey[100],
                            height: '23px',
                            width: '23px',
                            borderRadius: '70px',
                            marginRight: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        })}
                    >
                        {selectedBrandOrRestaurant?.isBrand ? <LaptopMoneyIcon /> : <PhoneIcon />}
                    </Box>
                    <Typography sx={{ fontSize: '16px' }}>{displayValue}</Typography>
                </Box>
            )}
            onChange={(e) => {
                console.log(e);
            }}
            IconComponent={() => <UnfoldMoreRounded />}
            MenuProps={{
                PaperProps: {
                    onClick: (e: { stopPropagation: () => any }) => e.stopPropagation(),
                },
                disableAutoFocusItem: true,
                disableAutoFocus: true,
                disableEnforceFocus: true,
                disablePortal: true,
            }}
        >
            <TextField
                size="medium"
                placeholder={t('Search')}
                value={searchTerm}
                onChange={(e) => {
                    e.stopPropagation();
                    setSearchTerm(e.target.value);
                }}
                onMouseDown={(e) => {
                    e.stopPropagation();
                }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onKeyDown={(e) => {
                    e.stopPropagation();
                }}
                sx={({ palette }) => ({
                    width: '100%',
                    marginTop: '0px !important',
                    paddingTop: '16px',
                    paddingX: '24px',
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: `${palette.grey[200]} !important`,
                        },
                        '&:hover fieldset': {
                            borderColor: `${palette.common.black} !important`,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: `${palette.primary.main} !important`,
                        },
                    },
                    '& .MuiInputBase-input': {
                        color: `${palette.grey[600]} !important`,
                        '&:hover': {
                            color: `${palette.common.black} !important`,
                        },
                    },
                })}
                InputProps={{
                    startAdornment: (
                        <Search
                            sx={({ palette }) => ({
                                marginRight: 1,
                                '&.MuiSvgIcon-root': {
                                    fill: `${palette.grey[600]} !important`,
                                },
                            })}
                        />
                    ),
                    onMouseDown: (e) => {
                        e.stopPropagation();
                    },
                    onClick: (e) => {
                        e.stopPropagation();
                    },
                }}
            />

            <ListSubheaderComponent title={t('Brands')} />
            <FormControl sx={{ width: '100%' }}>
                <RadioGroup
                    value={radioValue}
                    onChange={(e) => {
                        setTempSelection({
                            id: e.target.value,
                            isBrand: false,
                            name: restaurantList.find((r) => r.id === e.target.value)?.name || '',
                        });
                    }}
                >
                    {(!searchTerm || restaurant?.brand.name.toLowerCase().includes(searchTerm.toLowerCase())) && (
                        <MenuItemComponent
                            isBrand
                            key={restaurant?.brand.id}
                            selectedBrandOrRestaurant={tempSelection}
                            setSelectedBrandOrRestaurant={setTempSelection}
                            brandName={restaurant?.brand.name || t('Brand')}
                            brandId={restaurant?.brand.id}
                        />
                    )}

                    <ListSubheaderComponent title={t('Restaurants')} />

                    {filteredRestaurants.map((r) => (
                        <MenuItemComponent
                            isBrand={false}
                            key={r.id}
                            restaurant={r}
                            selectedBrandOrRestaurant={tempSelection}
                            setSelectedBrandOrRestaurant={setTempSelection}
                            brandName={restaurant?.brand.name || ''}
                        />
                    ))}
                </RadioGroup>
            </FormControl>

            <Box
                sx={{ p: 2, position: 'sticky', bottom: 0, bgcolor: 'background.paper', zIndex: 1 }}
                onClick={(e) => e.stopPropagation()}
            >
                <Button
                    fullWidth
                    variant="contained"
                    onClick={handleApply}
                    sx={({ palette }) => ({
                        backgroundColor: palette.primary.main,
                        '&:hover': {
                            backgroundColor: palette.primary.dark,
                        },
                    })}
                >
                    {t('Apply')}
                </Button>
            </Box>
        </CustomSelect>
    );
}
