import { alpha } from '@mui/material/styles';
import Select, { type SelectProps } from '@mui/material/Select';
import { ReactNode } from 'react';

type CustomSelectProps<T = unknown> = SelectProps<T> & {
    children: ReactNode;
};

export default function CustomSelect<T = unknown>(props: CustomSelectProps<T>) {
    const { children, ...selectProps } = props;

    return (
        <Select
            {...selectProps}
            sx={({ palette }) => ({
                marginInlineEnd: 1.25,
                borderRadius: '8px',
                width: '100%',
                textAlign: 'left',
                color: palette.primary.main,
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: alpha(palette.primary.main, 0.5),
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: alpha(palette.primary.main, 0.5),
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: alpha(palette.primary.main, 0.5),
                },
                '.MuiSvgIcon-root ': {
                    fill: palette.primary.main,
                },
            })}
        >
            {children}
        </Select>
    );
}
